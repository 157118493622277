import React, { createContext, useState, useEffect, useContext } from "react";
import { NotificationManager } from "react-notifications";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "./context.css";
import swal from "sweetalert";
import Swal2 from "sweetalert2";
import { httpGet, httpPost } from "../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../components/helpers/loader";
import { UserDataContext } from "./userContext";
import { ContextWrapState } from "./contextHandler";
import walletBalanceImg from "../assets/undraw_wallet_aym5.png";
import wallletAtm1 from "../assets/wallletAtm.png";
import wallletAtm2 from "../assets/wallletAtm2.png";
import wallletAtm3 from "../assets/wallletAtm3.png";
import t1 from "../assets/t1.png";
import t2 from "../assets/t2.png";
import t3 from "../assets/t3.png";
import t4 from "../assets/t4.png";
import t5 from "../assets/t5.png";
import FreeReadImg from "../assets/images/freeRead.png";
import dateFormater from "../components/helpers/dateFormater";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import TNSLogo from "../assets/logo.png";
import { useHistory } from "react-router-dom";
import usePayStack from "../hooks/usePayStack";

export const PaymentContext = createContext();

const FlutterwaveCheckout = window.FlutterwaveCheckout;

export const PaymentProvider = (props) => {
  const { setPayStackConfig, PayWithPayStack } = usePayStack();
  const MonnifySDK = window.MonnifySDK;
  const { UserInfo, userLocation, getUserProfile, userIsLogin } =
    useContext(UserDataContext);
  const [UserPaymentData, setUserPaymentData] = useState(null);
  const {
    setReloadCartDetails,
    reloadCartDetails,
    setcloseAddToCartModalGlob,
    closeAddToCartModalGlob,
  } = useContext(ContextWrapState);
  const history = useHistory();
  const [dUserData, setDUserData] = useState({});
  const [PublicationDetails, setPublicationDetails] = useState("");
  const [fundWalletAmount, setfundWalletAmount] = useState("");
  const [flutterConfig, setFlutterConfig] = useState({});
  const [showPaymentOptions, setshowPaymentOptions] = useState(false);
  const [CONshowPaymentOptions, setCONshowPaymentOptions] = useState(false);
  const [payWithWalletDetails, setpayWithWalletDetails] = useState(null);
  const [payWithMonifyDetails, setpayWithMonifyDetails] = useState(null);
  const [getPublicationDetails, setgetPublicationDetails] = useState(null);
  const [multi, setmulti] = useState("");
  const [subAccountSuccess, SetsubAccountSuccess] = useState(false);

  let handleFlutterPayment = useFlutterwave;

  useEffect(() => {
    if (userIsLogin) {
      getUserInfo();
      getBanksForUSSDTransfer();
    }
  }, [userIsLogin]);

  const getUserInfo = () => {
    if (UserInfo) {
      let email = UserInfo.email;
      let phone_number = UserInfo.phone;
      let name = `${UserInfo.firstName} ${UserInfo.lastName}`;

      setDUserData({ email, phone_number, name });
    }
  };

  let rediretOnpaymentSuccess = null;

  const [paymentResponse, setpaymentResponse] = useState("");
  const [PaymentDataFromNewpaperContext, setPaymentDataFromNewpaperContext] =
    useState("");

  const AddToCart = async (
    amount,
    subscriptionType,
    currency,
    publicationId
  ) => {
    console.log(amount, subscriptionType, currency, publicationId);
    showLoader();
    let data = {
      amount,
      subscriptionType,
      currency,
    };
    let res = await httpPost(`add_cart/${publicationId}`, data);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }
      hideLoader();
      setReloadCartDetails(!reloadCartDetails);
      setcloseAddToCartModalGlob(false);
      NotificationManager.success("Publication added to cart.");

      console.log(res.data);
    }
  };

  // $ CART MULTIPLE CHECKOUT
  const multipleCheckout = async (data, total) => {
    setmulti("multi");
    const { wallet } = UserInfo;
    let paymentType = "card";
    if (Number(wallet?.amount) >= Number(total)) {
      paymentType = "wallet";
    } else {
      paymentType = "card";
    }

    let publicationIds = data.map((pubAray) => {
      return {
        id: pubAray.publicationId,
        amount: pubAray.amount,
        currency: "NGN",
        subscriptionType: pubAray.subscriptionType,
      };
    });
    let sendData = {
      totalAmount: total,

      paymentMethod: paymentType,
      currency: "NGN",
      publicationIds,
    };

    console.log(sendData);
    showLoader();
    let res = await httpPost("multiple/subscribe", sendData);
    if (res) {
      setmulti("multi");
      if (res.code == 201) {
        if (paymentType == "wallet") {
          NotificationManager.success(
            "You have successfully paid for all item in cart "
          );
          window.location.href = `/subscription`;
          return;
        }
        // const ft = {
        //   public_key: res.data.publicKey,
        //   tx_ref: res.data.tx_ref,
        //   amount: total,
        //   currency: "NGN",
        //   payment_options: "card,mobilemoney,ussd",
        //   customer: {
        //     email: UserInfo.email,
        //     name: `${UserInfo.firstName} ${UserInfo.lastName}`,
        //   },
        //   meta: {
        //     subscriptionId: res.data.subscriptionId,
        //     // publicationId: publicationId,
        //   },
        //   customizations: {
        //     title: "TheNewsPaperStand",
        //     description: "TheNewsPaperStand Payment Gateway",
        //     logo: TNSLogo,
        //   },
        // };
        // user/subscription
        // await setFlutterConfig(ft);

        // await setFlutterConfig(ft);
        // PayWithMonnify(
        //   res.data.publicKey,
        //   res.data.monifyContractCode,
        //   res.data.tx_ref,
        //   total,
        //   res.data.subscriptionId,
        //   null,
        //   "multi",
        //   ft
        // );
        setPayStackConfig({
          email: UserInfo.email,
          reference: res.data.tx_ref,
          amount: total,
        });
        setOpenComplete(true);
        hideLoader();
      }
    }
    console.log(res);

    console.log("data>>>>", sendData);
  };

  const sendPaymentDetailsToBackend = async (
    amount,
    subscriptionType,
    currency,
    publicationId,
    isPaid,
    firstName,
    lastName,
    email
  ) => {
    console.log("sendPaymentDetailsToBackend");
    console.log("PAYMENT DETAILS", {
      amount,
      subscriptionType,
      currency,
      publicationId,
      isPaid,
      firstName,
      lastName,
    });

    const walletData = {
      amount,
      subscriptionType,
      currency,
      publicationId,
      isPaid,
      firstName,
      lastName,
      email,
    };

    // setpayWithWalletDetails(walletData);

    setpayWithMonifyDetails({
      amount: amount,
      subscriptionType: subscriptionType,
      currency: currency,
      publicationId: publicationId,
      isPaid: isPaid,
      firstName: firstName,
      lastName: lastName,
      email: email,
    });

    console.log("AMOUNT>>>>>CHECK", amount);

    const payWithMonifyData = {
      amount: amount,
      subscriptionType: subscriptionType,
      currency: currency,
      publicationId: publicationId,
      isPaid: isPaid,
      firstName: firstName,
      lastName: lastName,
      email: email,
      type: "CARD",
    };
    const { wallet } = UserInfo;
    console.log(">>>AMOUNT", Number(wallet?.amount), Number(amount));
    if (Number(wallet?.amount) >= Number(amount)) {
      console.log("WALLET", wallet);
      payWithWallet(walletData);
    } else {
      HandlePayWithMonifyFun(payWithMonifyData);
    }
  };

  const showPaymentOptionsFun = () => {
    setshowPaymentOptions(!showPaymentOptions);
  };

  const CONshowPaymentOptionsFun = () => {
    // setCONshowPaymentOptions(!CONshowPaymentOptions);
    CONinitPayment();
  };
  const payWithWallet = async ({
    publicationId,
    amount,
    currency,
    firstName,
    lastName,
    email,
    subscriptionType,
  }) => {
    showLoader();
    console.log("PUBLICATION ID>>>>>", publicationId);
    let data =
      getPublicationDetails === "subAccount"
        ? {
            publicationId,
            amount,
            currency,
            subscriptionType,
            firstName,
            lastName,
            email,
          }
        : {
            publicationId,
            amount,
            currency,
            subscriptionType,
          };
    let res =
      getPublicationDetails === "subAccount"
        ? await httpPost(`buy_for_me_wallet`, data)
        : await httpPost(`subscribe_wallet`, data);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }
      if (getPublicationDetails === "subAccount") {
        getUserProfile();
        hideLoader();
        return NotificationManager.success(
          "You have successfully added a new sub account",
          "Yepp!"
        );
      }
      setpayWithWalletDetails(null);
      setpayWithMonifyDetails(null);
      localStorage.setItem("paidWithWallet", "true");
      window.location.href = `/view_paper/${publicationId}`;
      hideLoader();
    }
  };
  const CONpayWithWallet = async (data) => {
    showLoader();

    let res = await httpPost(`con/pay_with_wallet`, data);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }
      swal(
        "Congratulations!",
        "Your request was successful, you will get a mail as soon as your request for change of name is published",
        "success"
      ).then(() => {
        window.location.href = "/applications";
      });
      hideLoader();
    }
  };

  const PublicNoticepayWithWallet = async (data) => {
    showLoader();

    let res = await httpPost(`pn/pay_with_wallet`, data);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }

      swal({
        title: "Congratulations!",
        text: "Your request was successful, you will get a mail as soon as your request  is published",
        icon: "success",
        dangerMode: false,
      }).then(() => {
        window.location.href = "/applications";
      });

      hideLoader();
    }
  };

  const AchivepayWithWallet = async (data) => {
    showLoader();

    let res = await httpPost(`archive/pay_with_wallet`, data);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }
      swal(
        "Congratulations!",
        "Your request was successful, you will get a mail as soon as your request  is published",
        "success"
      ).then(() => {
        window.location.href = "/applications";
      });

      hideLoader();
    }
  };

  const [Insufficent, setInsufficent] = useState(false);
  const showInsufficentFun = () => {
    setInsufficent(!Insufficent);
  };

  const HandlePayWithMonifyFun = async ({
    amount,
    subscriptionType,
    currency,
    publicationId,
    isPaid,
    firstName,
    lastName,
    email,
    type,
    phoneNumber,
    countryCode,
  }) => {
    console.log("AMOUNT", amount);

    let data =
      getPublicationDetails === "subAccount"
        ? {
            amount: isPaid ? amount : 0,
            subscriptionType: subscriptionType,
            currency: currency,
            publicationId: publicationId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber,
            countryCode,
          }
        : {
            amount: isPaid ? amount : 0,
            subscriptionType: subscriptionType,
            currency: currency,
            publicationId: publicationId,
            phoneNumber,
            countryCode,
          };
    console.log("data from subscribe ", data, type);
    showLoader();

    // TODO: Implement PayStack

    let res =
      getPublicationDetails === "subAccount"
        ? await httpPost(`buy_for_me`, data)
        : await httpPost(`subscribe`, data);

    // TODO: On Success Send Reference Key To Backend

    if (res) {
      hideLoader();

      console.log("Payment Details", res);

      if (res.er) {
        console.log("res.ERRRR>>>", res.er);
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }
      console.log(">>>>>MONIFY RES", res);
      setpaymentResponse(res.data);
      setcloseAddToCartModalGlob(false);
      // const ft = {
      //   public_key: res.data.publicKey,
      //   tx_ref: res.data.tx_ref,
      //   amount: amount,
      //   currency: "NGN",
      //   payment_options: "card,mobilemoney,ussd",
      //   customer: {
      //     email: UserInfo.email,
      //     name: `${UserInfo.firstName} ${UserInfo.lastName}`,
      //   },
      //   meta: {
      //     subscriptionId: res.data.subscriptionId,
      //     publicationId: publicationId,
      //   },
      //   customizations: {
      //     title: "TheNewsPaperStand",
      //     description: "TheNewsPaperStand Payment Gateway",
      //     logo: TNSLogo,
      //   },
      // };

      // await setFlutterConfig(ft);

      // $ ------ PayWith Flutter -----
      // if (isPaid === true) {
      //   await setFlutterConfig(ft);
      //   PayWithMonnify(
      //     res.data.publicKey,
      //     res.data.monifyContractCode,
      //     res.data.tx_ref,
      //     amount,
      //     res.data.subscriptionId,
      //     publicationId,
      //     type,
      //     ft
      //   );
      //   hideLoader();
      // }
      // $ -X----- PayWith Flutter ----X-

      // $ ------ PayWith PayStack -----
      // TODO: Implement Pay With Pay Stack
      if (isPaid === true) {
        setPayStackConfig({
          email: UserInfo.email,
          reference: res.data.tx_ref,
          amount,
        });
        setOpenComplete(true);
        hideLoader();
      }
      // $ -X----- PayWith PayStack ----X-

      hideLoader();

      if (getPublicationDetails === "subAccount") {
        // NotificationManager.success(
        //   "You have successfully added a new sub account",
        //   "Yepp!"
        // );

        hideLoader();

        return;
      }
      if (isPaid === false) {
        hideLoader();
        NotificationManager.success(
          "You have successfully subscribed to this publication"
        );
        setpayWithWalletDetails(null);
        setpayWithMonifyDetails(null);
        window.location.href = `/view_paper/${publicationId}`;
      }
    }
  };

  const onPaymentSubscribeSuccess = () => {
    setOpenComplete(false);
    // history.push("/user/subscription");
    window.location.reload();
  };

  const onPaymentClose = () => {
    setOpenComplete(false);
  };

  // Pay With FlutterWave
  const PayWithMonnify = (
    ApiKey,
    ContractCode,
    TXRef,
    amount,
    subscriptionId,
    publicationId,
    type,
    ft
  ) => {
    console.log(
      "data from monify ",
      ApiKey,
      ContractCode,
      TXRef,
      amount,
      subscriptionId,
      type,
      publicationId,
      ft
    );
    // alert(type);

    if (ft.public_key) {
      FlutterwaveCheckout({
        ...ft,
        callback: (response) => {
          type == "multi"
            ? verifyPaymentMulti(response.transaction_id)
            : verifyPayment(response.transaction_id);
          closePaymentModal(); // this will close the modal programmatically
        },
        onClose: () => {},
      });
    }
    hideLoader();
  };

  const verifyPaymentMulti = async (response) => {
    let d = response;
    console.log(">>>response>>>", d);
    // return;
    showLoader();
    let res = await httpGet(`verify_payment/${d}`);
    if (res) {
      hideLoader();
      if (res.er) {
        hideLoader();

        NotificationManager.error(res.er.message);
        return;
      }

      hideLoader();
      NotificationManager.success(
        "You have successfully paid for all item in cart"
      );
      window.location.href = `/subscription`;
    }
  };

  const verifyPayment = async (response) => {
    let d = response;
    console.log(">>>response>>>", d);
    // return;
    showLoader();
    let res = await httpGet(`verify_payment/${d}`);
    if (res) {
      hideLoader();
      if (res.er) {
        hideLoader();

        NotificationManager.error(res.er.message);
        return;
      }

      if (getPublicationDetails === "subAccount") {
        setgetPublicationDetails(null);
        SetsubAccountSuccess(true);
        swal(
          "Congratulations!",
          "You have successfully added a new sub account",
          "success"
        );
        setpayWithWalletDetails(null);
        setpayWithMonifyDetails(null);
        setgetPublicationDetails(null);
        // window.location.reload();
        return;
      }
      setTimeout(() => {
        setpayWithWalletDetails(null);
        setpayWithMonifyDetails(null);
        setgetPublicationDetails(null);

        window.location.href = `/view_paper/${res.data.publicationId}`;
      }, 400);
      hideLoader();
      console.log(res.data.publicationId);
    }
    hideLoader();
  };
  const [CONdataInitPayment, setCONdataInitPayment] = useState({});

  // $ CHANGE OF NAME PAYMENT
  const CONinitPayment = async (Condata) => {
    showLoader();
    const { publicationId, id, currency, amount, type } = Condata;
    const Data = {
      publicationId,
      id,
      currency,
      amount,
    };
    const res = await httpPost(`con/initiate_payment`, Data);
    if (res.code == 201) {
      hideLoader();
      const { public_key, monifyContractCode, tx_ref } = res.data;
      console.log("change of name Data>>>", res.data);
      // const ft = {
      //   public_key: res.data.publicKey,
      //   tx_ref: res.data.tx_ref,
      //   amount: amount,
      //   currency: "NGN",
      //   payment_options: "card,mobilemoney,ussd",
      //   customer: {
      //     email: UserInfo.email,
      //     name: `${UserInfo.firstName} ${UserInfo.lastName}`,
      //   },
      //   meta: {
      //     subscriptionId: res.data.subscriptionId,
      //     publicationId: publicationId,
      //   },
      //   customizations: {
      //     title: "TheNewsPaperStand",
      //     description: "TheNewsPaperStand Payment Gateway",
      //     logo: TNSLogo,
      //   },
      // };
      // payWithMonnifyChangeOfName(
      //   public_key,
      //   monifyContractCode,
      //   tx_ref,
      //   amount,
      //   id,
      //   publicationId,
      //   type,
      //   ft
      // );
      setPayStackConfig({
        email: UserInfo.email,
        reference: res.data.tx_ref,
        amount,
      });
      setOpenComplete(true);
      hideLoader();
    }
  };

  function payWithMonnifyChangeOfName(
    ApiKey,
    ContractCode,
    TXRef,
    amount,
    id,
    publicationId,
    type,
    ft
  ) {
    console.log("monifyyyy active>>>", MonnifySDK);
    console.log(
      "data from monify ",
      ApiKey,
      ContractCode,
      TXRef,
      amount,
      id,
      publicationId,
      type,
      ft
    );

    if (ft.public_key) {
      FlutterwaveCheckout({
        ...ft,
        callback: (response) => {
          verifyPaymentChangeOfName(response.transaction_id);
          closePaymentModal(); // this will close the modal programmatically
        },
        onClose: () => {},
      });
    }
  }

  const verifyPaymentChangeOfName = async (response) => {
    showLoader();
    let d = response;
    console.log(d);
    let res = await httpGet(`con/verify_payment/${d}`);
    if (res) {
      if (res.er) {
        hideLoader();

        NotificationManager.error(res.er.message);
        return;
      }
      CONshowPaymentOptionsFun();
      swal(
        "Congratulations!",
        "Your request was successful, you will get a mail as soon as your request for change of name is published",
        "success"
      );
      window.location.href = "/applications";
    }
  };

  // PN PAYMENT START

  const PUBLICNoticeinitPayment = async (Condata) => {
    showLoader();
    const { publicationId, id, currency, amount, type } = Condata;
    const Data = {
      publicationId,
      id,
      currency,
      amount,
    };
    const res = await httpPost(`pn/initiate_payment`, Data);
    if (res.code == 201) {
      hideLoader();
      const { public_key, monifyContractCode, tx_ref } = res.data;
      console.log("change of name Data>>>", res.data);
      const ft = {
        public_key: res.data.publicKey,
        tx_ref: res.data.tx_ref,
        amount: amount,
        currency: "NGN",
        payment_options: "card,mobilemoney,ussd",
        customer: {
          email: UserInfo.email,
          name: `${UserInfo.firstName} ${UserInfo.lastName}`,
        },
        meta: {
          subscriptionId: res.data.subscriptionId,
          publicationId: publicationId,
        },
        customizations: {
          title: "TheNewsPaperStand",
          description: "TheNewsPaperStand Payment Gateway",
          logo: TNSLogo,
        },
      };
      payWithMonnifyPUBLICNotice(
        public_key,
        monifyContractCode,
        tx_ref,
        amount,
        id,
        publicationId,
        type,
        ft
      );

      hideLoader();
    }
  };
  function payWithMonnifyPUBLICNotice(
    ApiKey,
    ContractCode,
    TXRef,
    amount,
    id,
    publicationId,
    type,
    ft
  ) {
    console.log("monifyyyy active>>>", MonnifySDK);
    console.log(
      "data from monify ",
      ApiKey,
      ContractCode,
      TXRef,
      amount,
      id,
      publicationId,
      type,
      ft
    );

    if (ft.public_key) {
      FlutterwaveCheckout({
        ...ft,
        callback: (response) => {
          verifyPaymentPUBLICNotice(response.transaction_id);
          closePaymentModal(); // this will close the modal programmatically
        },
        onClose: () => {},
      });
    }
  }

  const verifyPaymentPUBLICNotice = async (response) => {
    showLoader();
    let d = response;
    console.log(d);
    let res = await httpGet(`pn/verify_payment/${d}`);
    if (res) {
      hideLoader();
      if (res.er) {
        hideLoader();

        NotificationManager.error(res.er.message);
        return;
      }

      swal(
        "Congratulations!",
        "Your request was successful, you will get a mail as soon as your request for public notice is published",
        "success"
      );
      window.location.href = "/applications";
    }
  };

  // PUBLIC NOTICE ENDS HERE

  // ACHIVE_PAYMENT

  const AchiveinitPayment = async (Condata) => {
    showLoader();
    const { publicationId, id, currency, amount, type } = Condata;
    const Data = {
      publicationId,
      id,
      currency,
      amount,
    };
    const res = await httpPost(`archive/initiate_payment`, Data);
    if (res.code == 201) {
      hideLoader();
      const { public_key, monifyContractCode, tx_ref } = res.data;
      console.log("change of name Data>>>", res.data);
      const ft = {
        public_key: res.data.publicKey,
        tx_ref: res.data.tx_ref,
        amount: amount,
        currency: "NGN",
        payment_options: "card,mobilemoney,ussd",
        customer: {
          email: UserInfo.email,
          name: `${UserInfo.firstName} ${UserInfo.lastName}`,
        },
        meta: {
          subscriptionId: res.data.subscriptionId,
          publicationId: publicationId,
        },
        customizations: {
          title: "TheNewsPaperStand",
          description: "TheNewsPaperStand Payment Gateway",
          logo: TNSLogo,
        },
      };
      payWithMonnifyAchive(
        public_key,
        monifyContractCode,
        tx_ref,
        amount,
        id,
        publicationId,
        type,
        ft
      );

      hideLoader();
    }
  };
  function payWithMonnifyAchive(
    ApiKey,
    ContractCode,
    TXRef,
    amount,
    id,
    publicationId,
    type,
    ft
  ) {
    console.log("monifyyyy active>>>", MonnifySDK);
    console.log(
      "data from monify ",
      ApiKey,
      ContractCode,
      TXRef,
      amount,
      id,
      publicationId,
      type,
      ft
    );

    if (ft.public_key) {
      FlutterwaveCheckout({
        ...ft,
        callback: (response) => {
          console.log("response>>>>", response);
          verifyPaymentAchive(response.transaction_id);
          closePaymentModal(); // this will close the modal programmatically
        },
        onClose: () => {},
      });
    }
  }

  const verifyPaymentAchive = async (response) => {
    showLoader();
    let d = response;
    console.log(d);
    let res = await httpGet(`archive/verify_payment/${d}`);
    if (res) {
      hideLoader();
      if (res.er) {
        hideLoader();

        NotificationManager.error(res.er.message);
        return;
      }

      swal("Congratulations!", "Your request was successful", "success");
      window.location.href = "/applications";
    }
  };

  const [PaymentMetaData, setPaymentMetaData] = useState({
    subscriptionId: "",
    publicationId: "",
  });
  const [open, setOpen] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [showFreeReadModal, setShowFreeReadModal] = useState(false);
  const [freeReadModalData, setFreeReadModalData] = useState("");

  const toggleFreePdfModal = (type, data) => {
    setShowFreeReadModal(!showFreeReadModal);
    setFreeReadModalData(data);
  };

  const SubFreeRead = async () => {
    const data = { publicationId: freeReadModalData.id };
    showLoader();
    const res = await httpPost(`subscribe`, data);
    if (res) {
      if (res?.code == 201) {
        hideLoader();
        NotificationManager.success(
          "You have successfully subscribed to this publication"
        );
        window.location.href = `/view_paper/${freeReadModalData.id}`;
      }
    }
    hideLoader();
  };

  // T
  const ComfirmPaymentModal = () => {
    console.log(PaymentMetaData);

    return (
      <div className="comfirmPaymentModal">
        <Modal open={open} onClose={onCloseModal} center>
          <div className="inner-modal-wrap">
            <p className="xwijkds">
              You will be redirected to the payment page
            </p>
            <div className="fluterWvePayBTN">
              <MakePaymentfL />
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  const CompletePayment = () => {
    return (
      <div className="comfirmPaymentModal">
        <Modal
          open={openComplete}
          onClose={() => setOpenComplete(false)}
          center
        >
          <div
            className="inner-modal-wrap"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            <p className="xwijkds">Proceed to Payment</p>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="pay-now-button"
                onClick={() =>
                  PayWithPayStack(onPaymentSubscribeSuccess, onPaymentClose)
                }
              >
                Pay Now
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  console.log(UserInfo);

  const fundUserWalletFun = async () => {
    if (fundWalletAmount === "") {
      return NotificationManager.error("Please input an amount");
    }
    let data = {
      walletId: UserInfo?.wallet?.id,
      amount: fundWalletAmount,
      currency: userLocation,
    };
    showLoader();
    //console.log(data);
    let res = await httpPost(`fund_wallet`, data);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }

      // const ft = {
      //   public_key: res.data.publicKey,
      //   tx_ref: res.data.tx_ref,
      //   amount: fundWalletAmount,
      //   currency: "NGN",
      //   payment_options: "card,mobilemoney,ussd",
      //   customer: {
      //     email: UserInfo.email,
      //     name: `${UserInfo.firstName} ${UserInfo.lastName}`,
      //   },

      //   meta: {
      //     walletId: res.data.walletId,
      //   },
      //   customizations: {
      //     title: "TheNewsPaperStand",
      //     description: "TheNewsPaperStand Payment Gateway",
      //     logo: TNSLogo,
      //   },
      // };

      // FundWalletWithMonnify(ft);
      setPayStackConfig({
        email: UserInfo.email,
        reference: res.data.tx_ref,
        amount: fundWalletAmount,
      });
      setOpenComplete(true);

      hideLoader();
      setfundWalletAmount("");
    }
  };

  function FundWalletWithMonnify(ft) {
    setFlutterConfig(ft);

    FlutterwaveCheckout({
      ...ft,
      callback: (response) => {
        verifyFundWaletPayment(response.transaction_id);
        closePaymentModal(); // this will close the modal programmatically
      },
      onClose: () => {},
    });
  }

  const verifyFundWaletPayment = async (response) => {
    showLoader();
    let d = response;

    let res = await httpGet(`verify_fund_wallet/${d}`);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }
      setOpenWalletFundModal();
      getUserProfile();
      swal(
        "Wallet funded successfully!",
        "You can now purchase any publication from your wallet balance.",
        "success"
      );
      // console.log("verifyyyy payment", res.data);
      hideLoader();
    }
  };

  const [bankList, setBankList] = useState([]);
  const getBanksForUSSDTransfer = async () => {
    let res = await httpGet(`banks`);
    if (res) {
      if (res.er) {
        hideLoader();

        return;
      }
      let filterUssdTemplate = res.data.bankList.filter((data) => {
        return data.ussdTemplate !== null;
      });
      setBankList(filterUssdTemplate);
      console.log(res.data);
      hideLoader();
    }
  };
  const [bankCode, setbankCode] = useState(null);
  const getUSSDCode = (e) => {
    e.preventDefault();
    let index = e.target.value;
    const getCode = bankList.find((data, i) => {
      return data.code === index;
    });

    console.log("filter", getCode);
    setbankCode(getCode.ussdTemplate);
  };

  const MakePaymentfL = () => {
    return <div></div>;
  };

  const MakePayment = () => {
    console.log(UserInfo);
    console.log(dUserData);
  };
  const getUserWallet = () => {
    getUserProfile();
    setOpenWalletFundModal();
  };
  const [WalletOpen, setWalletOpen] = useState(false);
  const OpenWalletModal = () => setWalletOpen(true);
  const setOpenWalletModal = () => setWalletOpen(false);

  const upDateSubscription = async (
    amount,
    subscriptionType,
    currency,
    publicationId,
    subscriptionId
  ) => {
    let data = {
      amount,
      subscriptionType,
      currency,
      publicationId,
      subscriptionId,
    };
    let res = await httpPost(`upgrade_subscription`, data);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }
      NotificationManager.success(res.message);

      console.log(res);
    }
  };

  const WalletModal = () => {
    return (
      <Modal open={WalletOpen} onClose={setOpenWalletModal}>
        <div
          style={{ backgroundColor: "transparent" }}
          className="inner-modal-wrap-tr "
        >
          <div className="waletBalanceModalWrap">
            <img
              style={{ width: "90%", height: "199px", borderRadius: "5px" }}
              src={walletBalanceImg}
              alt=""
            />
            <div className="walletBalanceDetailsuyf">
              <p className="kyudry">Wallet Balance</p>
              <span className="jhddsr">
                {userLocation === "Nigeria" ? "NGN" : "USD"}{" "}
                {UserInfo ? UserInfo?.wallet?.amount : "0"}
              </span>
            </div>
            <div className="fundWalletMethodsHeader">Fund wallet with</div>

            <div className="walletActionsButton" style={{ width: "100%" }}>
              {/* <button onClick={() => funndWaletFun("wallet")}>
 {" "}
 <img src={wallletAtm1} alt="" /> Card
 </button> */}

              <button
                onClick={() => funndWaletFun("wallet")}
                style={{ width: "60%" }}
              >
                {" "}
                <img src={wallletAtm2} alt="" /> Fund Wallet
              </button>

              {/* <button onClick={() => funndWaletFun("USSDTransfer")}>
                {" "}
                <img src={wallletAtm3} alt="" />
                USSD Transfer
              </button> */}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const [WalletFundOpen, setWalletFundOpen] = useState(false);
  const OpenWalletFundModal = () => setWalletFundOpen(true);
  const setOpenWalletFundModal = () => setWalletFundOpen(false);
  const [WalletMethodOpen, setWalletMethodOpen] = useState("bankTransfer");
  const WalletFundModal = () => {
    return <div></div>;
  };

  const funndWaletFun = (type) => {
    setOpenWalletModal();
    OpenWalletFundModal();
    setWalletMethodOpen(type);
  };

  const goBackFundModal = () => {
    setOpenWalletFundModal();
    OpenWalletModal();
  };

  const handleFundWalletInput = (e) => {
    console.log(e.target.value);
    setfundWalletAmount(e.target.value);
  };

  return (
    <PaymentContext.Provider
      value={{
        MakePayment,
        MakePaymentfL,
        setPublicationDetails,
        ComfirmPaymentModal,
        CompletePayment,
        sendPaymentDetailsToBackend,
        AddToCart,
        rediretOnpaymentSuccess,
        WalletModal,
        setOpenWalletModal,
        OpenWalletModal,
        upDateSubscription,
        setgetPublicationDetails,
        SetsubAccountSuccess,
        subAccountSuccess,
        payWithMonnifyChangeOfName,
        verifyPaymentChangeOfName,
        CONpayWithWallet,
        CONshowPaymentOptionsFun,
        CONinitPayment,
        setCONdataInitPayment,
        toggleFreePdfModal,
        HandlePayWithMonifyFun,
        AchiveinitPayment,
        AchivepayWithWallet,
        PUBLICNoticeinitPayment,
        PublicNoticepayWithWallet,
        multipleCheckout,
        setmulti,
      }}
    >
      {props.children}
      {WalletOpen && <WalletModal />}
      {showPaymentOptions && (
        <Modal open={showPaymentOptions} onClose={showPaymentOptionsFun} center>
          <div className="inner-modal-wrap-tr" style={{ background: "white" }}>
            <div className="waletBalanceModalWrap">
              <div className="xModalT">
                <i
                  onClick={showPaymentOptionsFun}
                  class="fas fa-arrow-left"
                ></i>
                <i onClick={showPaymentOptionsFun} className="fas fa-times"></i>
              </div>
            </div>

            <div className="walletTransfeerDetails walletTransfeerDetailsCard">
              <img style={{ width: "65px", height: "70px" }} src={t1} alt="" />
              <p className="fundvjgahdh iq2hk">Procceed to payment</p>
            </div>
            <div className="walletActionsButton walletActionsButtonCard">
              {/* <button onClick={payWithWallet}>
 {" "}
 <img src={t5} alt="" />
 Pay with wallet
 </button> */}
              {/* <button onClick={() => HandlePayWithMonifyFun("CARD")}>
 {" "}
 <img src={wallletAtm1} alt="" /> Pay with card
 </button> */}

              {/* <button onClick={() => HandlePayWithMonifyFun("USSD")}>
 {" "}
 <img src={wallletAtm1} alt="" /> Pay with USD
 </button> */}

              <button onClick={() => HandlePayWithMonifyFun("CARD")}>
                {" "}
                {/* <img src={t4} alt="" /> */}
                <img src={t5} alt="" />
                Pay Now
              </button>

              {/* <button onClick={() => funndWaletFun("wallet")}>
 {" "}
 <img src={t4} alt="" /> Fund Wallet
 </button> */}
            </div>
          </div>
        </Modal>
      )}

      {CONshowPaymentOptions && (
        <Modal
          open={CONshowPaymentOptions}
          onClose={CONshowPaymentOptionsFun}
          centerF
        >
          <div className="inner-modal-wrap-tr" style={{ background: "white" }}>
            <div className="waletBalanceModalWrap">
              <div className="xModalT">
                <i
                  onClick={CONshowPaymentOptionsFun}
                  class="fas fa-arrow-left"
                ></i>
                <i
                  onClick={CONshowPaymentOptionsFun}
                  className="fas fa-times"
                ></i>
              </div>
            </div>

            <div className="walletTransfeerDetails walletTransfeerDetailsCard">
              <img style={{ width: "65px", height: "70px" }} src={t1} alt="" />
              <p className="fundvjgahdh iq2hk">Procceed to payment</p>
            </div>
            <div className="walletActionsButton walletActionsButtonCard">
              {/* <button onClick={payWithWallet}>
 {" "}
 <img src={t5} alt="" />
 Pay with wallet
 </button> */}
              <button onClick={() => CONinitPayment("CARD")}>
                {" "}
                <img src={wallletAtm1} alt="" /> Pay with card
              </button>

              {/* <button onClick={() => CONinitPayment("USSD")}>
 {" "}
 <img src={wallletAtm1} alt="" /> Pay with USD
 </button> */}

              <button onClick={() => CONinitPayment("ACCOUNT_TRANSFER")}>
                {" "}
                {/* <img src={t4} alt="" /> */}
                <img src={t5} alt="" />
                Pay with USSD
              </button>

              {/* <button onClick={() => funndWaletFun("wallet")}>
 {" "}
 <img src={t4} alt="" /> Fund Wallet
 </button> */}
            </div>
          </div>
        </Modal>
      )}

      {Insufficent && (
        <Modal open={Insufficent} onClose={showInsufficentFun}>
          <div className="inner-modal-wrap-tr" style={{ background: "white" }}>
            <div className="waletBalanceModalWrap">
              <div className="xModalT">
                <i onClick={showInsufficentFun} class="fas fa-arrow-left"></i>
                <i onClick={showInsufficentFun} className="fas fa-times"></i>
              </div>
            </div>

            <div className="walletTransfeerDetails walletTransfeerDetailsCard">
              <img style={{ width: "65px", height: "70px" }} src={t1} alt="" />
              <p className="fundvjgahdh iq2hk">
                Insufficent wallet balance click fund <br />
                wallet to fund wallet
              </p>
            </div>
            <div className="walletActionsButton walletActionsButtonCard">
              <button onClick={() => funndWaletFun("wallet")}>
                {" "}
                <img src={wallletAtm1} alt="" /> Fund Wallet
              </button>

              <button
                style={{ backgroundColor: "red" }}
                onClick={showInsufficentFun}
              >
                {" "}
                Go back
              </button>
            </div>
          </div>
        </Modal>
      )}
      {WalletFundOpen && (
        <Modal open={WalletFundOpen} onClose={setOpenWalletFundModal}>
          <div className="inner-modal-wrap-tr" style={{ background: "white" }}>
            <div className="waletBalanceModalWrap">
              <div className="xModalT">
                <i onClick={goBackFundModal} class="fas fa-arrow-left"></i>
                <i
                  onClick={setOpenWalletFundModal}
                  className="fas fa-times"
                ></i>
              </div>

              <div className="fund-ur-wallet-type">
                {WalletMethodOpen === "wallet" ? (
                  <div className="walletTransfeerDetails">
                    <img
                      style={{ width: "65px", height: "70px" }}
                      src={t3}
                      alt=""
                    />
                    <p className="fundvjgahdh">
                      Enter the amount you want to fund <br />
                      your wallet with
                    </p>
                    <div className="fundWalletTInput">
                      <input type="text" onChange={handleFundWalletInput} />
                    </div>
                    <div
                      style={{ marginTop: "27px" }}
                      className="tranferDoneBtn"
                    >
                      <button onClick={fundUserWalletFun}>Proceed</button>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {WalletMethodOpen === "bankTransfer" ? (
                  <div className="walletTransfeerDetails">
                    <img
                      style={{ width: "65px", height: "70px" }}
                      src={t1}
                      alt=""
                    />
                    <p className="fundvjgahdh">
                      Fund your wallet by doing a bank transfer to the below{" "}
                      account detail.
                    </p>

                    <div className="userBankDrtailswrap">
                      <div className="uTrD">
                        <p>
                          {UserInfo
                            ? `${UserInfo.firstName} ${UserInfo.lastName}`
                            : ""}
                        </p>
                        <p>{UserInfo ? UserInfo.wallet.bankName : ""}</p>
                        <p>{UserInfo ? UserInfo.wallet.accountNumber : ""}</p>
                      </div>
                    </div>

                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                      Your wallet will be funded instantly!
                    </p>

                    <div
                      style={{ marginTop: "27px" }}
                      className="tranferDoneBtn"
                    >
                      <button onClick={getUserWallet}>Done</button>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {WalletMethodOpen === "USSDTransfer" ? (
                  <div className="walletTransfeerDetails">
                    <img
                      style={{ width: "64px", height: "54px" }}
                      src={t2}
                      alt=""
                    />
                    <p className="fundvjgahdh">
                      Select your bank to see <br />
                      USSD transfer template
                    </p>

                    <div className="">
                      <div className="fundUssdBankDetails">
                        <select onChange={(e) => getUSSDCode(e)} name="" id="">
                          <option value="" disabled selected>
                            Select Bank
                          </option>

                          {bankList
                            ? bankList.map((data, i) => {
                                return (
                                  <option value={data.code}>{data.name}</option>
                                );
                              })
                            : []}
                        </select>
                      </div>
                    </div>

                    <p style={{ marginTop: "20px" }}>
                      {bankCode !== null ? bankCode : ""}
                    </p>

                    <div className="userBankDrtailswrap">
                      <div className="uTrD">
                        <p>
                          {UserInfo
                            ? `${UserInfo.firstName} ${UserInfo.lastName}`
                            : ""}
                        </p>
                        <p>{UserInfo ? UserInfo.wallet.bankName : ""}</p>
                        <p>{UserInfo ? UserInfo.wallet.accountNumber : ""}</p>
                      </div>
                    </div>

                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                      Your wallet will be funded instantly!
                    </p>

                    <div
                      style={{ marginTop: "27px" }}
                      className="tranferDoneBtn"
                    >
                      <button onClick={getUserWallet}>Done</button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showFreeReadModal ? (
        <div className="comfirmPaymentModal">
          <Modal open={showFreeReadModal} onClose={toggleFreePdfModal} center>
            <div className="inner-modal-wrap">
              <div className="freeReadModalImWrap">
                <img src={FreeReadImg} alt="" />
              </div>
              <p className="xwijkds">
                {/* Congratulation */}
                Congratulations
              </p>
              <div
                className="freeReadModalImWrap"
                style={{ paddingTop: "5px" }}
              >
                <p style={{ textAlign: "center", width: "90%" }}>
                  {" "}
                  You are one of the lucky users to get a free read from the
                  <span style={{ color: "#ee7f0d" }}>
                    {" "}
                    {freeReadModalData?.name}{" "}
                  </span>{" "}
                  {/* <span
                    style={
                      !freeReadModalData?.freeEndDate ? { display: "none" } : {}
                    }
                  >
                    which will expire on the{" "}
                    <span>{dateFormater(freeReadModalData?.freeEndDate)}</span>
                  </span> */}
                </p>
              </div>
              <div className="fluterWvePayBTN" style={{ marginBottom: "14px" }}>
                <button
                  style={{
                    color: "white",
                    background: "#ee7f0d",
                    padding: "13px",
                    borderRadius: "4px",
                  }}
                  onClick={SubFreeRead}
                >
                  Accept Offer
                </button>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        ""
      )}
    </PaymentContext.Provider>
  );
};
