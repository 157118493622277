import React, { useState, useRef, useEffect } from "react";
import { usePdf } from "@mikecousins/react-pdf";
import { httpGet, httpPost } from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import { NotificationManager } from "react-notifications";
import SamplePdf from "./sample.pdf";
import "./pdf.css";
import BackBtn from "../../assets/pdf-back.png";
import FrontBtn from "../../assets/pdf-forward.png";
import Iframe from 'react-iframe'
import Loader from "react-loader-spinner";
export default function PdfViewer(props) {

useEffect(()=>{
  purchasedEdition()
},[])

  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);

  const { pdfDocument, pdfPage } = usePdf({
    file: SamplePdf,
    page,
    canvasRef,
  });
  const [Showloading, setLoading] = useState(true);
  const [DisableNext, setDisableNext] = useState(false);
  const [nextP, setnextP] = useState(false);
  const Next = () => {
    if (page === pdfDocument.numPages) {
      if (page === 1) {
        setDisableBack(true);
        return;
      }
    } else {
      // setDisableBack(false);
      setPage(page + 1);
      setDisableBack(false);
      setnextP(true);
    }
  };

  const [pdfPath, sepPdfPath] = useState();
  const purchasedEdition = async () => {
    const res = await httpGet(`purchased_edition/${props.match.params.id}`);
    if (res) {
      if (res.er) {
        NotificationManager.error(res.er.message);
        return;
      }

      let d = res.data.editions;
      console.log(d);
      sepPdfPath(res.data.edition.pdfPath);
      console.log(pdfPath)
      setLoading(false)
     
    }
  };

  const [DisableBack, setDisableBack] = useState(false);
  const Back = () => {
    if (page === 1) {
      setDisableBack(true);
      return;
    } else {
      setPage(page - 1);
      // setnextP(false);
    }
  };
  if (Showloading) {
    return (
      <div className="row h-100 justify-content-center align-items-center app-spinner">
        <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
      </div>
    );
  } else {

  return (
    <div>
      {/* {!pdfDocument && <span>Loading...</span>} */}

      <div className="pdf-viewer-wrap">
      <Iframe
                url={`https://heyzine.com/api1?pdf=${pdfPath}&k=d8b77d7915d7c3c2`}
                width="100%"
                height="100%"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative" />
      </div>
      {/* 
      <div class="cover">
        <div class="book">
          <label for="page-2" class="book__page book__page--4">
            <div class="page__content">
              <h1 class="page__content-title">I</h1>
              <div class="page__content-blockquote">
                <h1>pagge</h1>
              </div>
              <div class="page__number">3</div>
            </div>
          </label>
        </div>
      </div> */}
    </div>
  );
}}
